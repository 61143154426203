/**
 * Flex styles
 * Description: Reusable class-based CSS flex styles.
 * https://getbootstrap.com/docs/4.3/utilities/flex/
 */

.d-flex {
  display: flex;
}


.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-column {
  flex-direction: column;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}


.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}


.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-center {
  align-items: center;
}
.align-items-baseline {
  align-items: baseline;
}
.align-items-stretch {
  align-items: stretch;
}


.align-self-start {
  align-self: flex-start;
}
.align-self-end {
  align-self: flex-end;
}
.align-self-center {
  align-self: center;
}
.align-self-baseline {
  align-self: baseline;
}
.align-self-stretch {
  align-self: stretch;
}


.align-content-start {
  align-content: flex-start;
}
.align-content-end {
  align-content: flex-end;
}
.align-content-center {
  align-content: space-between;
}
.align-content-around {
  align-content: space-around;
}
.align-content-stretch {
  align-content: stretch;
}


.flex-fill {
  flex: 1 1 auto !important;
}


.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

/**
 * Ordering of columns
 */

.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}

@mixin make-orders($size) {
  @for $i from 1 to $grid-column-count {
    .order-#{$size}-#{$i} {
      order: $i;
    }
  }
}

@each $key, $value in $grid-breakpoints {
  @media (min-width: $value) {
    @include make-orders($key);
  };
};
